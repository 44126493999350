export const CONTENT_TYPES = {
    SINGLE_ASSET: 'DigitalSingleAssetRecord', //TODO: Uses lib component
    ASSET_WITH_HEADER: 'DigitalAssetWithHeaderAndDescriptionRecord', //TODO: Uses lib component
    HEADER_AND_DESCRIPTION: 'DigitalHeaderAndDescriptionRecord', //TODO: Uses lib component
    ASSETS_GALLERY: 'DigitalAssetsGalleryRecord', //TODO: Uses lib component
    PROJECT_SECTION_HEADER: 'DigitalProjectSectionHeaderRecord', //TODO: Uses lib component
    PROJECT_RANGE: 'DigitalProjectRangeRecord', //TODO: Uses lib component
    PINNED_TEXT_WITH_IMAGE: 'DigitalPinnedTextWithImageRecord', //TODO: Uses lib component
    TECHNOLOGIES_TABLE: 'DigitalTechnologiesTableRecord', //TODO: Uses lib component
    ABOUT_TEAM: 'DigitalAboutTeamModuleRecord',
    ALL_PROJECTS_PAGE: 'DesignAllProjectsPageRecord',
    SOCIAL_MEDIA: 'DigitalSocialMediaModuleRecord',
    SINGLE_PAGE: 'DesignSinglePageRecord',
    SINGLE_PROJECT: 'DesignSingleProjectRecord',
    SINGLE_HOW_WE_WORK_PAGE: 'DigitalSingleHowWeWorkPageRecord',
    SINGLE_HOW_WE_WORK_PAGE_NEW: 'CommonHowWeWorkPageRecord',
    FAQ: "DigitalFaqRecord",
    HOW_WE_CREATE_STORY: ''
}
