import React, {memo, useEffect} from 'react';
import {CMS_ELEMENT_TYPENAME, CONTENT_TYPES as COMMON_CONTENT_TYPES} from '@repo/utils/constants';
import {CONTENT_TYPES} from '@/utils/constants';
import { useScrollContext } from '@repo/ui';
import dynamic from "next/dynamic";
const Loading = () => {
	const scroll = useScrollContext()
	useEffect(() => {
		return () => {
			scroll.collectComponents()
			scroll.refresh(false)
		}
	}, []);
	return null
}

const DynamicSingleAsset = dynamic(() => import('@repo/ui').then(mod => mod.SingleAsset), { loading: Loading, ssr: false });
const DynamicAssetWithHeaderAndDescription = dynamic(() => import('@repo/ui').then(mod => mod.AssetWithHeaderAndDescription), { loading: Loading, ssr: false });
const DynamicHeaderAndDescription = dynamic(() => import('@repo/ui').then(mod => mod.HeaderAndDescription), { loading: Loading, ssr: false });
const DynamicStyledGallery = dynamic(() => import('@repo/ui').then(mod => mod.StyledGallery), { loading: Loading, ssr: false });
const DynamicSectionHeader = dynamic(() => import('@repo/ui').then(mod => mod.SectionHeader), { loading: Loading, ssr: false });
const DynamicProjectRange = dynamic(() => import('@repo/ui').then(mod => mod.ProjectRange), { loading: Loading, ssr: false });
const DynamicProjectScope = dynamic(() => import('@repo/ui').then(mod => mod.ProjectScope), { loading: Loading, ssr: false });
const DynamicTextModules = dynamic(() => import('@repo/ui').then(mod => mod.TextModules), { loading: Loading, ssr: false });
const DynamicProjectTechnologiesTable = dynamic(() => import('@repo/ui').then(mod => mod.ProjectTechnologiesTable), { loading: Loading, ssr: false });
const DynamicPinnedTextWithImage = dynamic(() => import('@repo/ui').then(mod => mod.PinnedTextWithImage), { loading: Loading, ssr: false });
const DynamicProjectSlider = dynamic(() => import('@repo/ui').then(mod => mod.ProjectSlider), { loading: Loading, ssr: false });
const DynamicVideoBanner = dynamic(() => import('@repo/ui').then(mod => mod.VideoBanner), { loading: Loading, ssr: false });
const DynamicContactForm = dynamic(() => import('@repo/ui').then(mod => mod.ContactForm), { loading: Loading, ssr: false });
const DynamicCheckOthers = dynamic(() => import('@repo/ui').then(mod => mod.CheckOthers), { loading: Loading, ssr: false });
const DynamicPleoContext = dynamic(() => import('@repo/ui').then(mod => mod.PleoContext), { loading: Loading, ssr: false });
const DynamicPleoBranches = dynamic(() => import('@repo/ui').then(mod => mod.PleoBranches), { loading: Loading, ssr: false });
const DynamicWhoWeWorkedWith = dynamic(() => import('@repo/ui').then(mod => mod.WhoWeWorkedWith), { loading: Loading, ssr: false });
const DynamicHorizontalHeaderModule = dynamic(() => import('@repo/ui').then(mod => mod.HorizontalHeaderModule), { loading: Loading, ssr: false });
const DynamicQuickContact = dynamic(() => import('@repo/ui').then(mod => mod.QuickContact), { loading: Loading, ssr: false });
const DynamicProductsGrid = dynamic(() => import('@repo/ui').then(mod => mod.ProductsGrid), { loading: Loading, ssr: false });
const DynamicDescriptionLinePleo = dynamic(() => import('@repo/ui').then(mod => mod.DescriptionLinePleo), { loading: Loading, ssr: false });
const DynamicBlobSlogan = dynamic(() => import('@repo/ui').then(mod => mod.BlobSlogan), { loading: Loading, ssr: false });
const DynamicFeaturedProjects = dynamic(() => import('@repo/ui').then(mod => mod.FeaturedProjects), { loading: Loading, ssr: false });
const DynamicNewsThumbnails = dynamic(() => import('@repo/ui').then(mod => mod.NewsThumbnails), { loading: Loading, ssr: false });
const DynamicHowWeCreateStory = dynamic(() => import('@repo/ui').then(mod => mod.HowWeCreateStory), { loading: Loading, ssr: false });
const DynamicServicesCards = dynamic(() => import('@repo/ui').then(mod => mod.ServicesCards), { loading: Loading, ssr: false });
const DynamicHowWeWorkModule = dynamic(() => import('@repo/ui').then(mod => mod.HowWeWorkModule), { loading: Loading, ssr: false });
const DynamicTilesWithDescription = dynamic(() => import('@repo/ui').then(mod => mod.TilesWithDescription), { loading: Loading, ssr: false });
const DynamicAreasOfExpertise = dynamic(() => import('@repo/ui').then(mod => mod.AreasOfExpertise), { loading: Loading, ssr: false });
const DynamicTiles = dynamic(() => import('@repo/ui').then(mod => mod.Tiles), { loading: Loading, ssr: false });
const DynamicCheckOtherServices = dynamic(() => import('@repo/ui').then(mod => mod.CheckOtherServices), { loading: Loading, ssr: false });
const DynamicTechnologiesTiles = dynamic(() => import('@repo/ui').then(mod => mod.TechnologiesTiles), { loading: Loading, ssr: false });
const DynamicBenefitsModule = dynamic(() => import('@repo/ui').then(mod => mod.BenefitsModule), { loading: Loading, ssr: false });
const DynamicClientsAboutUs = dynamic(() => import('@repo/ui').then(mod => mod.ClientsAboutUs), { loading: Loading, ssr: false });
const DynamicNumbersModule = dynamic(() => import('@repo/ui').then(mod => mod.NumbersModule), { loading: Loading, ssr: false });
const DynamicProjectsGridWrapper = dynamic(() => import('@repo/ui').then(mod => mod.ProjectsGridWrapper), { loading: Loading, ssr: false });
const DynamicCareerModule = dynamic(() => import('@repo/ui').then(mod => mod.CareerModule), { loading: Loading, ssr: false });
const DynamicJobOfferForm = dynamic(() => import('@repo/ui').then(mod => mod.JobOfferForm), { loading: Loading, ssr: false });
const DynamicDescriptionSlogan = dynamic(() => import('@repo/ui').then(mod => mod.DescriptionSlogan), { loading: Loading, ssr: false });
const DynamicSocialMedia = dynamic(() => import('@repo/ui').then(mod => mod.SocialMedia), { loading: Loading, ssr: false });
const DynamicFAQ = dynamic(() => import('@repo/ui').then(mod => mod.FAQ), { loading: Loading, ssr: false });
const DynamicHeaderDescriptionButtonBanner = dynamic(() => import('@repo/ui').then((mod) => mod.HeaderDescriptionButtonBanner), {
	loading: Loading,
	ssr: false
});
const DynamicStoryModule = dynamic(() => import('@repo/ui').then((mod) => mod.Story), {
	loading: Loading,
	ssr: false
});

const Component = ({ item, typename, globals, hidePleoTags, isSinglePage, index, locale, storyData }) => {
	switch (typename) {
		case CONTENT_TYPES.SINGLE_ASSET:
			return <DynamicSingleAsset key={item?.id || index} data={item} index={index} />;
		case CONTENT_TYPES.ASSET_WITH_HEADER:
			return <DynamicAssetWithHeaderAndDescription key={item?.id || index} data={item} />;
		case CONTENT_TYPES.HEADER_AND_DESCRIPTION:
			return <DynamicHeaderAndDescription key={item?.id || index} id={item} data={item} />;
		case CONTENT_TYPES.ASSETS_GALLERY:
			return <DynamicStyledGallery key={item?.id || index} data={item} />;
		case COMMON_CONTENT_TYPES.VIDEO_BANNER:
			return <DynamicVideoBanner data={item} locale={locale} webGL/>;
		case CONTENT_TYPES.PROJECT_SECTION_HEADER:
			return <DynamicSectionHeader key={item?.id || index} data={item} />;
		case CONTENT_TYPES.PROJECT_RANGE:
			return <DynamicProjectRange key={item?.id || index} data={item} index={index} locale={locale} />;
		case COMMON_CONTENT_TYPES.PROJECT_SCOPE:
			return <DynamicProjectScope key={item?.id || index} data={item} locale={locale} />;
		case COMMON_CONTENT_TYPES.TEXT_MODULE:
			return <DynamicTextModules key={item.id || index} data={item} locale={locale} />;
		case CONTENT_TYPES.PINNED_TEXT_WITH_IMAGE:
			return <DynamicPinnedTextWithImage key={item?.id || index} data={item} locale={locale} />;
		case COMMON_CONTENT_TYPES.PROJECT_SLIDER:
			return <DynamicProjectSlider key={item?.id || index} data={item} locale={locale} />;
		case CONTENT_TYPES.TECHNOLOGIES_TABLE:
			return <DynamicProjectTechnologiesTable key={item?.id || index} data={item} locale={locale} />;
		case COMMON_CONTENT_TYPES.CHECK_OTHERS:
			return <DynamicCheckOthers data={item} />
		case COMMON_CONTENT_TYPES.DESCRIPTION_SLOGAN:
			return <DynamicDescriptionSlogan data={item}  />;
		case COMMON_CONTENT_TYPES.FORM:
			return <DynamicContactForm data={item} locale={locale} />;
		case COMMON_CONTENT_TYPES.PLEO_CONTEXT:
			return <DynamicPleoContext data={item}  />;
		case COMMON_CONTENT_TYPES.PLEO_BRANCHES:
			return <DynamicPleoBranches data={item} />
		case COMMON_CONTENT_TYPES.WHO_WE_WORKED_WITH:
			return <DynamicWhoWeWorkedWith data={item} />
		case COMMON_CONTENT_TYPES.HORIZONTAL_HEADER:
			return <DynamicHorizontalHeaderModule key={item?.id} data={item} />
		case COMMON_CONTENT_TYPES.QUICK_CONTACT:
			return <DynamicQuickContact  data={item} />
		case COMMON_CONTENT_TYPES.PRODUCTS_GRID:
			return <DynamicProductsGrid data={item} />
		case COMMON_CONTENT_TYPES.DESCRIPTION_LOGO_PLEO:
			return <DynamicDescriptionLinePleo data={item} />
		case COMMON_CONTENT_TYPES.COMMON_BLOB_SLOGAN:
			return <DynamicBlobSlogan data={item} />
		case COMMON_CONTENT_TYPES.FEATURED_PROJECTS:
			return <DynamicFeaturedProjects data={item} type="design"  />
		case COMMON_CONTENT_TYPES.NEWS_THUMBNAILS:
			return <DynamicNewsThumbnails data={item} globals={globals} hidePleoTags={hidePleoTags} />
		case COMMON_CONTENT_TYPES.HOW_WE_CREATE_STORY:
			return <DynamicHowWeCreateStory data={item} isSinglePage={isSinglePage} />
		case COMMON_CONTENT_TYPES.SERVICES_CARDS:
			return <DynamicServicesCards data={item} />
		case COMMON_CONTENT_TYPES.HOW_WE_WORK_MODULE:
			return <DynamicHowWeWorkModule data={item} />
		case COMMON_CONTENT_TYPES.TILES_WITH_DESCRIPTION:
			return <DynamicTilesWithDescription data={item} />
		case COMMON_CONTENT_TYPES.AREAS_OF_EXPERTISE:
			return <DynamicAreasOfExpertise data={item} />
		case COMMON_CONTENT_TYPES.TILES_MODULE:
			return <DynamicTiles data={item} />
		case CONTENT_TYPES.SOCIAL_MEDIA:
			return <DynamicSocialMedia data={item} />
		case COMMON_CONTENT_TYPES.CHECK_OTHER_SERVICES:
			return <DynamicCheckOtherServices data={item} />
		case COMMON_CONTENT_TYPES.TECHNOLOGIES_TILES:
			return <DynamicTechnologiesTiles data={item} />
		case COMMON_CONTENT_TYPES.BENEFITS_MODULE:
			return <DynamicBenefitsModule data={item} />
		case COMMON_CONTENT_TYPES.CLIENTS_ABOUT_US_RECORD:
			return <DynamicClientsAboutUs data={item} />
		case COMMON_CONTENT_TYPES.NUMBERS_RECORD:
			return <DynamicNumbersModule data={item} />
		case COMMON_CONTENT_TYPES.PROJECTS_GRID:
			return <DynamicProjectsGridWrapper data={item} type="design"  />
		case COMMON_CONTENT_TYPES.CAREER_MODULE:
			return <DynamicCareerModule data={item} />
		case COMMON_CONTENT_TYPES.JOB_OFFER_FORM:
			return <DynamicJobOfferForm data={item} />
		case CONTENT_TYPES.FAQ:
			return <DynamicFAQ data={item} />
		case COMMON_CONTENT_TYPES.HEADER_DESCRIPTION_BUTTON_BANNER_RECORD:
			return <DynamicHeaderDescriptionButtonBanner data={item} type="design" />
		case COMMON_CONTENT_TYPES.STORY_MODULE:
			return <DynamicStoryModule data={item} items={storyData} />
		default:
			return null;
	}
}

const ExtendedContent = ({ content, locale, globals, isServicePage, hidePleoTags, isSinglePage, storyData }) => {
	if (Array.isArray(content)) {
		return content.map((item, index) => (
			<Component
				key={item?.id || index}
				item={item}
				typename={item[CMS_ELEMENT_TYPENAME]}
				index={index}
				locale={locale}
				globals={globals}
				isServicePage={isServicePage}
				hidePleoTags={hidePleoTags}
				isSinglePage={isSinglePage}
				storyData={storyData}
			/>
		));
	}
	return null;
};
export default memo(ExtendedContent);
